






































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextareaShort from '@/common/components/STextareaShort.vue';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'UCIrvineChem1LCELN1Observations2',
  components: {STextarea, TableInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        glasswareType: '',
        glasswareMass: '',
        molarMass: '',
        vSoln: '',
        sampleCalculation: '',
        massTableRows: [
          {standard: '', sucroseMass: '', solutionMass: '', sucroseMol: '', dSoln: ''},
          {standard: '', sucroseMass: '', solutionMass: '', sucroseMol: '', dSoln: ''},
        ],
      },
      massColumns: [
        {label: 'Standard', component: STextareaShort, prop: 'standard', width: '10%'},
        {label: 'Sucrose Mass (g)', component: STextareaShort, prop: 'sucroseMass', width: '20%'},
        {
          label: 'Solution Mass m(2) (g)',
          component: STextareaShort,
          prop: 'solutionMass',
          width: '25%',
        },
        {label: '[Sucrose] (M)', component: STextareaShort, prop: 'sucroseMol', width: '20%'},
        {label: 'd(soln) (g/mL)', component: STextareaShort, prop: 'dSoln', width: '25%'},
      ],
      questions: [
        {
          text: 'Glassware Type:',
          inputName: 'glasswareType',
        },
        {
          text: 'Glassware Mass (m1) (g)',
          inputName: 'glasswareMass',
        },
        {
          text: 'Molar Mass (sucrose) (g/mol):',
          inputName: 'molarMass',
        },
        {
          text: 'Vsoln (mL):',
          inputName: 'vSoln',
        },
        {
          text: 'Sample Calculation:',
          inputName: 'sampleCalculation',
        },
      ],
    };
  },
  methods: {},
});
